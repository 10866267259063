import $http from "./index";

// 查询持卡分享记录列表
export const getShareCardRecordPage = async (params) => {
  return await $http.get("/app/info/shareCard/back/queryShareRecordPage", {
    params,
  });
};

// 查询分享记录详情
export const getShareRecordDetail = async (shareId) => {
  return await $http.get("/app/info/shareCard/back/queryShareRecordDetail", {
    params: {
      cardShareId: shareId,
    },
  });
};
