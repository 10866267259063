<template>
  <el-dialog
    v-model="dialogVisible"
    :max-height="500"
    :scroll="true"
    title="详情"
    :width="800"
  >
    <el-card>
      <el-descriptions :column="2" label-class-name="desc-label">
        <el-descriptions-item label="会员ID">
          <el-tag size="small">{{ detailData.cardHolderMemberId }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="会员名称">
          <el-tag type="warning" size="small">{{
            detailData.cardHolderMemberName
          }}</el-tag>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions :column="2" label-class-name="desc-label">
        <el-descriptions-item label="会员持卡ID">
          <el-tag size="small">{{ detailData.mCardId }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="卡名称">
          <el-tag type="warning" size="small">{{
            detailData.mCardName
          }}</el-tag>
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions :column="2" label-class-name="desc-label">
        <el-descriptions-item label="分享时间">
          <el-tag size="small">{{
            formatDate(detailData.creationTime)
          }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item label="">
          <el-tag
            type="warning"
            size="small"
            v-if="detailData.limitItemFlag && detailData.isFixGroupCard"
            >不限项目分组卡</el-tag
          >
          <el-tag
            type="warning"
            size="small"
            v-else-if="detailData.limitItemFlag"
            >不限项目次卡</el-tag
          >
        </el-descriptions-item>
      </el-descriptions>
    </el-card>

    <p class="mt-10px">分享卡内容</p>
    <el-card>
      <p>分享总次数：{{ detailData.cardSharedTimes }}</p>
      <!-- 普通次卡 -->
      <div
        v-if="detailData.limitItemFlag != true && !detailData.isFixGroupCard"
      >
        <div
          v-for="(item, index) in detailData.shareItems"
          :key="index"
          class="item_name_row"
        >
          <div class="item_name">{{ item.mCardItemName }}</div>
          <div class="number">x {{ item.itemSharedTimes }}</div>
        </div>
      </div>
      <!-- 不限项目次卡 -->
      <div
        v-else-if="
          detailData.limitItemFlag == true && !detailData.isFixGroupCard
        "
      >
        <div
          v-for="(item, index) in detailData.shareItems"
          :key="index"
          class="item_name_row"
        >
          <div class="item_name">{{ item.mCardItemName }}</div>
        </div>
      </div>
      <!-- 不限项目分组卡 -->
      <div
        v-else-if="
          detailData.limitItemFlag == true && detailData.isFixGroupCard
        "
      >
        <div
          class="no_limit_group_card"
          v-for="(group, groupIndex) in detailData.shareGroupItems"
          :key="groupIndex"
        >
          <div class="group_name_row flex flex-row justify-between">
            <div class="group_name">
              {{ group.groupType }}{{ group.groupCode }}
            </div>
            <div class="right">分组次数：{{ group.groupSharedTimes }}</div>
          </div>
          <div
            v-for="(item, index) in group.shareItems"
            :key="index"
            class="item_name_row"
          >
            <div class="item_name">{{ item.mCardItemName }}</div>
          </div>
        </div>
      </div>
    </el-card>
  </el-dialog>
</template>
<script lang="js" setup>
import { ref, defineOptions, defineExpose } from "vue";
import { formatDate } from "@/utils/dateUtils";
import { getShareRecordDetail } from "@/http/shareCard";

/** 持卡分享记录的详情 */
defineOptions({ name: "ShareCardRecordDetail" });

const dialogVisible = ref(false); // 弹窗的是否展示
const detailLoading = ref(false); // 表单的加载中
const detailData = ref({}); // 详情数据

/** 打开弹窗 */
const open = async (id) => {
  dialogVisible.value = true;
  // 设置数据
  detailLoading.value = true;

  try {
    const data = await getShareRecordDetail(id);
    // console.log("===分享记录详情：", data);
    detailData.value = data;
  } finally {
    detailLoading.value = false;
  }
};
defineExpose({ open }); // 提供 open 方法，用于打开弹窗
</script>

<style lang="scss">
.no_limit_group_card {
  margin-bottom: 10px;
  .group_name_row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 13px;
    .group_name {
      font-weight: 500;
      font-size: 14px;
      color: #333333;
      line-height: 14px;
      text-align: left;
      font-style: normal;
    }
    .right {
      font-weight: 500;
      font-size: 14px;
      color: #333333;
      line-height: 14px;
      text-align: center;
      font-style: normal;
    }
  }
}
.item_name_row {
  display: flex;
  justify-content: space-between;

  padding-bottom: 13px;
  .item_name {
    font-weight: 400;
    font-size: 14px;
    color: #999999;
    line-height: 14px;
    text-align: left;
    font-style: normal;
  }
  .number {
    font-weight: 400;
    font-size: 14px;
    color: #333333;
    line-height: 14px;
    text-align: center;
    font-style: normal;
    margin-left: 8px;
  }
}
</style>
