<template>
  <div>
    <!-- 搜索工作栏 -->
    <el-form
      ref="queryFormRef"
      :inline="true"
      :model="queryParams"
      class="-mb-15px"
      label-width="68px"
    >
      <el-form-item label="客户" prop="holderMemberNameOrPhone">
        <el-input
          v-model="queryParams.holderMemberNameOrPhone"
          class="!w-240px"
          clearable
          placeholder="请输入姓名或手机号"
          @keyup.enter="handleQuery"
        />
      </el-form-item>
      <el-form-item label="状态" prop="claimStatus">
        <el-select
          v-model="queryParams.claimStatus"
          class="!w-240px"
          clearable
          placeholder="请选择状态"
        >
          <el-option label="未领取" :value="0" />
          <el-option label="已领取" :value="1" />
          <el-option label="已过期" :value="2" />
        </el-select>
      </el-form-item>
      <el-form-item label="分享时间" prop="times">
        <el-date-picker
          v-model="queryParams.times"
          :default-time="[new Date('1 00:00:00'), new Date('1 23:59:59')]"
          class="!w-240px"
          end-placeholder="结束日期"
          start-placeholder="开始日期"
          type="daterange"
          value-format="YYYY-MM-DD HH:mm:ss"
        />
      </el-form-item>
      <el-form-item>
        <el-button @click="handleQuery" icon="el-icon-search"> 搜索 </el-button>
        <el-button @click="resetQuery" icon="el-icon-refresh"> 重置 </el-button>
        <!-- <el-button
          v-hasPermi="['system:role:export']"
          :loading="exportLoading"
          plain
          type="success"
          @click="handleExport"
        >
          <Icon class="mr-5px" icon="ep:download" />
          导出
        </el-button> -->
      </el-form-item>
    </el-form>
  </div>

  <!-- 列表 -->
  <div>
    <el-table v-loading="loading" :data="list" show-overflow-tooltip>
      <el-table-column
        align="center"
        label="分享ID"
        prop="cardShareId"
        show-overflow-tooltip
      />
      <el-table-column
        align="center"
        label="客户名称"
        prop="cardHolderMemberName"
      />
      <el-table-column
        align="center"
        label="客户ID"
        prop="cardHolderMemberId"
        width="170"
      />
      <el-table-column
        align="center"
        label="手机号"
        prop="cardHolderMemberPhone"
      />
      <el-table-column
        align="center"
        label="持卡ID"
        prop="mCardId"
        width="170"
      />
      <el-table-column
        align="center"
        label="可使用总次数"
        prop="cardSharedTimes"
      />
      <el-table-column align="center" label="状态" prop="claimStatus">
        <template #default="scope">
          <span v-if="scope.row.claimStatus === 0">未领取</span>
          <span v-else-if="scope.row.claimStatus === 1">已领取</span>
          <span v-else-if="scope.row.claimStatus === 2">已过期</span>
          <span v-else>scope.row.claimStatus</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="领取人ID"
        prop="recipientMemberId"
        width="170"
      />
      <el-table-column
        align="center"
        label="分享时间"
        prop="creationTime"
        width="180"
      />
      <el-table-column width="90" align="center" fixed="right" label="操作">
        <template #default="scope">
          <el-button link type="primary" @click="openDetail(scope.row)">
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页 -->
    <el-pagination
      class="fenye"
      @current-change="handleCurrentChange"
      :current-page="queryParams.page"
      :page-size="queryParams.pageSize"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
      next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
      background
      layout="total, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
  <!-- 表单弹窗：详情 -->
  <ShareCardRecordDetail ref="detailRef" />
</template>
<script lang="js" setup>
import { ref, reactive, onMounted, nextTick, watch, defineOptions } from "vue";

import { getShareCardRecordPage } from "@/http/shareCard";
import ShareCardRecordDetail from "./ShareCardRecordDetail.vue";

// 持卡分享记录列表
defineOptions({ name: "ShareCardRecords" });


const loading = ref(true); // 列表的加载中
const total = ref(0); // 列表的总页数
const list = ref([]); // 列表的数据
const queryParams = reactive({
  page: 1,
  pageSize: 10,
  holderMemberNameOrPhone: "",
  claimStatus: undefined,
  times: [],
  shareTimeStart: undefined,
  shareTimeEnd: undefined,
});
const queryFormRef = ref(); // 搜索的表单

watch(
  () => queryParams.times,
  (val) => {
    let start = undefined;
    let end = undefined;
    if (val) {
      start = val[0];
      end = val[1];
    }
    queryParams.shareTimeStart = start;
    queryParams.shareTimeEnd = end;
  }
);

/** 查询列表 */
const getList = async () => {
  loading.value = true;
  try {
    const data = await getShareCardRecordPage(queryParams);
    list.value = data.items;
    total.value = data.totalCount;
  } finally {
    loading.value = false;
  }
};

/** 搜索按钮操作 */
const handleQuery = () => {
  queryParams.page = 1;
  getList();
};

/** 重置按钮操作 */
const resetQuery = () => {
  queryFormRef.value.resetFields();
  nextTick(() => {
    handleQuery();
  });
};

let handleCurrentChange = (index) => {
  queryParams.page = index;
  getList();
};

/** 详情操作 */
const detailRef = ref();
const openDetail = (row) => {
  detailRef.value.open(row.cardShareId);
};

/** 初始化 **/
onMounted(() => {
  getList();
});
</script>
